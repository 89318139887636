import DocumentIcon from 'assets/DocumentIcon';
import DocumentPreviewIcon from 'assets/DocumentPreviewIcon';
import classNames from 'classnames';
import CloseButton from 'components/Buttons/CloseButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import Modal from 'components/Modal';
import FormModal from 'components/Modal/FormModal';
import useModalHandler from 'hooks/useModalHandler';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import CollapsibleTableRow from 'scenes/Kyc/components/CollapsibleTableRow';
import setClassSuffix from 'utilities/services/ClassManager';
import DocumentsPreview from './components/DocumentsPreview';
import './styles.scss';

const DocumentPackageTableRow = ({ className, index, rowInfo, onPackageItemDelete }) => {
  const { itemName } = rowInfo;
  const { isOpen: isDocumentPreviewOpen, open, close } = useModalHandler();
  const {
    open: openConfirmationMOdal,
    close: closeConfirmationModal,
    isOpen: isConfirmationModalOpen,
  } = useModalHandler();
  const documentPreviewRef = useRef(null);

  const setSufix = setClassSuffix(`${className}__column`);

  const classes = classNames('ickyc-document-package-table-row', className);

  const openDocumentPreview = useCallback(
    e => {
      open(e);
      e.stopPropagation();
    },
    [open],
  );

  const closeDocumentPreview = useCallback(
    e => {
      close(e);
      e.stopPropagation();
    },
    [close],
  );

  useLayoutEffect(() => {
    const element = documentPreviewRef?.current;
    const handleClickOutside = event => {
      if (isDocumentPreviewOpen && !element.contains(event.target)) {
        closeDocumentPreview();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [documentPreviewRef, isDocumentPreviewOpen, closeDocumentPreview]);

  return (
    <>
      <CollapsibleTableRow
        className={classes}
        row={
          <>
            <td className={setSufix('__index')}>
              <span>{index + 1}</span>
            </td>
            <td className={setSufix('__type')}>
              <span>{<DocumentIcon />}</span>
            </td>
            <td className={setSufix('__name')}>
              <span>{itemName}</span>
            </td>
            <td className={setSufix('__submission')} onClick={openDocumentPreview}>
              <span>
                <DocumentPreviewIcon onClick={openDocumentPreview} />
              </span>
            </td>
            <td className={setSufix('__remove')}>
              <span>
                <DeleteButton onClick={openConfirmationMOdal} />
              </span>
            </td>
          </>
        }
      >
        <DocumentsPreview {...rowInfo} />
      </CollapsibleTableRow>
      {isDocumentPreviewOpen && (
        <Modal hideModal={closeDocumentPreview} title="Document Preview" onClick={e => e.stopPropagation()}>
          <div className="ickyc-document-package-table-row__document-modal" ref={documentPreviewRef}>
            <CloseButton
              className="ickyc-document-package-table-row__close-button"
              transparent
              onClick={closeDocumentPreview}
            />
            <DocumentsPreview {...rowInfo} />
          </div>
        </Modal>
      )}
      {isConfirmationModalOpen && (
        <FormModal
          hideModal={closeConfirmationModal}
          title="Remove Package Item"
          className="ickyc-confirmation-modal"
          onSubmit={onPackageItemDelete}
        >
          <span className="ickyc-confirmation-message">Are you sure you want to delete this item ?</span>
        </FormModal>
      )}
    </>
  );
};
export default DocumentPackageTableRow;
