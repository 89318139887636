import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BatchContext } from '../../../utilities/services/contexts';
import Table from '../Table';
import './styles.scss';

const CheckboxTable = ({
  values,
  TableControlsComponent,
  onControls,
  disabled,
  className,
  idPropName,
  initialSelected,
  onSelectedItemsChange,
  onLocalValsChanage,
  ...props
}) => {
  const [localValues, setLocalValues] = useState(values);
  const [areSelectedDisabled, setAreSelectedDisabled] = useState(false);

  const selectedAll = useMemo(() => {
    const selectedOptionsLength = localValues.filter(val => val.checked).length;
    return localValues.length > 0 && localValues.length === selectedOptionsLength;
  }, [localValues]);

  const listOfSelectedIDs = useMemo(
    () => localValues.filter(locVal => locVal?.checked).map(locVal => locVal[idPropName]),
    [localValues],
  );

  const listOfSelectedValues = useMemo(() => localValues.filter(locVal => locVal?.checked), [localValues]);

  const onSelectAll = useCallback(() => {
    setLocalValues(prev => prev.map(p => ({ ...p, checked: !selectedAll })));
  }, [selectedAll]);

  const applyToMultipleRows = useCallback(
    val => {
      setLocalValues(prev => {
        const newValues = prev.map(p =>
          p.checked ? { ...p, value: val instanceof Object ? { ...p.value, ...val } : val } : p,
        );
        if (onControls) {
          onControls(
            val,
            newValues.filter(v => v.checked).map(({ id }) => id),
          );
        }
        return newValues;
      });
    },
    [onControls],
  );

  const onCheckboxClick = useCallback(id => {
    setLocalValues(prev => prev.map(p => (p[idPropName] === id ? { ...p, checked: !p.checked } : p)));
  }, []);

  const onControlsClick = useCallback(
    (id, value) => {
      setLocalValues(prev => {
        const newValues = prev.map(p => (p[idPropName] === id ? { ...p, value } : p));
        if (onControls) {
          onControls(value, [id]);
        }
        return newValues;
      });
    },
    [onControls],
  );

  const disableControls = useMemo(() => localValues.every(lv => !lv.checked), [localValues]);

  useEffect(() => {
    setLocalValues(values);
  }, [values]);

  useEffect(() => {
    onSelectedItemsChange(listOfSelectedIDs);
    onLocalValsChanage(listOfSelectedValues);
  }, [listOfSelectedIDs, listOfSelectedValues, onSelectedItemsChange, onLocalValsChanage]);

  const TableControls = useCallback(() => {
    return (
      <>
        <label>
          <input
            data-lpignore="true"
            type="checkbox"
            checked={selectedAll}
            onChange={onSelectAll}
            disabled={disabled}
          />{' '}
          Select All
        </label>
        <TableControlsComponent disabled={disableControls} onClick={applyToMultipleRows} />
      </>
    );
  }, [selectedAll, onSelectAll, disableControls, applyToMultipleRows, disabled]);
  return (
    <BatchContext.Provider value={{ listOfSelectedIDs, setAreSelectedDisabled, areSelectedDisabled }}>
      <Table
        values={localValues}
        {...props}
        onCheckboxClick={onCheckboxClick}
        onControlsClick={onControlsClick}
        tableControls={TableControlsComponent && TableControls}
        className={className || 'ickyc-checkbox-table'}
        selectedValues={listOfSelectedValues}
      />
    </BatchContext.Provider>
  );
};

CheckboxTable.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }),
  withPagination: PropTypes.bool,
  handleParamsChange: PropTypes.func,
  headerData: PropTypes.shape({
    sortCriteria: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  tableControls: PropTypes.elementType,
  TableControlsComponent: PropTypes.elementType,
  rowControls: PropTypes.elementType,
  tableRow: PropTypes.elementType.isRequired,
  headerRow: PropTypes.elementType,
  updating: PropTypes.bool,
  shouldReset: PropTypes.bool,
  onControls: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  idPropName: PropTypes.string,
  onSelectedItemsChange: PropTypes.func,
  onLocalValsChanage: PropTypes.func,
};
CheckboxTable.defaultProps = {
  onChange: () => {},
  withPagination: false,
  tableControls: undefined,
  rowControls: undefined,
  values: [],
  pagination: {},
  handleParamsChange: () => {},
  headerData: {},
  options: [],
  updating: false,
  shouldReset: false,
  headerRow: null,
  TableControlsComponent: null,
  onControls: () => {},
  disabled: true,
  className: undefined,
  idPropName: 'id',
  onSelectedItemsChange: () => {},
  onLocalValsChanage: () => {},
};
export default CheckboxTable;
