import { Lock } from '@material-ui/icons';
import Accordion from 'components/Accordion';
import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import ModuleTitleSettings from '../ModuleTitleSettings';
import ThirdPartyOptionsForm from '../ThirdPartyOptionsForm';
import './styles.scss';

const ThirdPartyConfirmationModule = ({ index, indexToShow, isDropAllowed, hasModuleError }) => {
  const className = 'ickyc-table';

  const {
    input: { value: toggleValue },
  } = useField(`modules[${index}].isEnabled`);

  return (
    <CollapsibleTableRow
      className={className}
      rowClassName={hasModuleError ? 'ickyc-table__row--error' : ''}
      withIndexColumn
      index={indexToShow}
      isToggled={toggleValue}
      isDropAllowed={isDropAllowed}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Third Party Confirmation</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            <Field
              preview
              name={`modules[${index}].isEnabled`}
              component={ToggleField}
              showCustomText
              toggleOnText="Enabled"
            />
          </td>
        </>
      }
    >
      <ModuleTitleSettings index={index} withDescription withParentsBackground />
      <div className="tpc-module__accordians-box">
        <Accordion
          title={<span>Third Party Options</span>}
          accordionOpen
          className={index % 2 === 0 ? 'ickyc-gray-section' : 'ickyc-white-section'}
        >
          <ThirdPartyOptionsForm index={index} />
        </Accordion>
        <Accordion
          title={<span>Automations</span>}
          accordionOpen
          className={index % 2 === 0 ? 'ickyc-gray-section' : 'ickyc-white-section'}
        >
          <Field
            component={ToggleField}
            label="Set status of the record to Accepted if no issues were found and Third Party confirmed the details"
            name={`modules[${index}].thirdPartyAutomationSubmodule.acceptIfNoIssues`}
          />
        </Accordion>
      </div>
    </CollapsibleTableRow>
  );
};

ThirdPartyConfirmationModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
  isDropAllowed: PropTypes.number,
  hasModuleError: PropTypes.number,
};

ThirdPartyConfirmationModule.defaultProps = {
  isDropAllowed: false,
  hasModuleError: false,
};

export default ThirdPartyConfirmationModule;
